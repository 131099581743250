import React, { useEffect, useState } from "react";
import "./Bank.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";

const Bank = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]); // Initialize list as an empty array
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [companies, setCompanies] = useState([]); // State for companies
  const [selectedCompany, setSelectedCompany] = useState(""); // State for selected company
  const [revertedClients, setRevertedClients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRevertedClients, setSelectedRevertedClients] = useState([]);

  useEffect(() => {
    BankList();
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      // Filter banks when a company is selected
      handleCompanyChange({ target: { value: selectedCompany } });
    }
  }, [selectedCompany, list]);

  const BankList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-bank"
      );
      console.log("Bank List Response:", response.data.data); // Debugging log
      setList(response.data.data || []); // Ensure that we always set a valid array
      setFilteredClients(response.data.data || []); // Set both list and filtered data initially
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company"
      );
      setCompanies(response.data.data || []); // Set the companies list
    } catch (error) {
      console.error("Error fetching companies:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddUser = () => {
    navigate("/addbank", { state: { company_id: selectedCompany } });
  };

  const handleEditUser = (client) => {
    navigate("/editbank", { state: { client } });
  };

  const DeleteApi = async (bankId) => {
    // Ask for confirmation before proceeding with deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the deletion
    }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/delete-bank",
        { bank_id: bankId }
      );

      if (response.status === 200) {
        alert(response.data.message);
        BankList(); // Refresh the bank list after deletion
      } else {
        alert("Failed to delete the bank record. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // Handle search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = list?.filter((client) =>
      client.bank_as_per_account_name.toLowerCase().includes(value)
    );

    setFilteredClients(filtered || []); // Ensure filteredClients is set to an empty array if filtered is undefined
  };

  // Handle company selection
  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);

    console.log("Selected Company ID:", companyId);

    // Filter the banks based on the selected company
    if (companyId) {
      const companyBanks = list.filter(
        (bank) => bank.bank_company && bank.bank_company === parseInt(companyId) // Correct property comparison
      );
      console.log("Filtered Banks after company selection:", companyBanks);

      setFilteredClients(companyBanks); // Set the filtered clients
    } else {
      setFilteredClients(list); // Show all banks if no company is selected
    }
  };
  // New function to fetch reverted clients
  const fetchRevertedClients = async (bankId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/bank/revert/list",
        { bank_id: bankId }
      );
      if (response.data.result) {
        setRevertedClients(response.data.data); // Set the reverted clients data
      } else {
        console.error(
          "Failed to fetch reverted clients:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching reverted clients:", error);
    }
  };
  const handleRevert = (clientId) => {
    setShowModal(true);
    fetchRevertedClients(clientId); // Fetch reverted clients when opening the modal
  };
  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSelectRevertedClient = (client) => {
    if (selectedRevertedClients.includes(client)) {
      setSelectedRevertedClients((prev) => prev.filter((c) => c !== client));
    } else {
      setSelectedRevertedClients((prev) => [...prev, client]);
    }
  };
  // New function to revert selected clients
  const handleRevertSelected = async () => {
    const token = localStorage.getItem("token");
    const r_ids = selectedRevertedClients?.map((client) => client.r_id); // Collect r_id of selected clients

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims//bank/reverting",
        { r_id: r_ids }, // Send r_ids as an array
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.result) {
        alert(response.data.message); // Show success message
        handleCloseModal(); // Close the modal
        BankList(selectedCompany); // Refresh the client list
        setSelectedRevertedClients([]); // Reset selected clients
      } else {
        alert("Failed to revert clients: " + response.data.message);
      }
    } catch (error) {
      console.error("Error reverting clients:", error);
    }
  };
  //.............Export Bank Api..............//

  const handleExport = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/bank/export",
        {},
        {
          headers: {
            company_id: selectedCompany, // Pass selected company_id as a header
          },
        }
      );

      if (response.data.result && response.data.filepath) {
        const fileUrl = response.data.filepath;

        // Trigger file download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "sales.xlsx"); // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
      } else {
        alert("Failed to export the sales data. Please try again.");
      }
    } catch (error) {
      console.error("Error exporting sales data:", error);
      alert("An error occurred during export. Please try again.");
    }
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <button className="export-button" onClick={handleExport}>
            <i className="fas fa-upload"></i> Export
          </button>
          <button className="import-button">
            <i className="fas fa-download"></i> Import
          </button>
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
          />

          <div className="company-filter">
            <label className="text1">Select Company:</label>
            <select
              value={selectedCompany}
              onChange={handleCompanyChange}
              className="company-dropdown"
            >
              <option value="">Select a company</option>
              {companies?.map((company) => (
                <option key={company.c_id} value={company.c_id}>
                  {company.c_name}
                </option>
              ))}
            </select>
          </div>

          <button onClick={handleAddUser}>
            ADD BANK
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>CLIENT NAME</th>
            <th>NAME AS PER BANK</th>
            <th>COMPANY NAME</th>
            <th>ACCOUNT NUMBER</th>
            <th>IBAN/IFSC/ROUTING NO</th>
            <th>NAME OF BANK</th>
            <th>BRANCH</th>
            <th>WIRE NUMBER</th>

            <th>PAYOUT AMOUNT</th>
            <th>FIRST PAYOUT DATE</th>
            <th>PAYMENT DATE</th>
            <th>COUNTRY</th>
            <th>ACTIONS</th>
            <th>REVERT OPTION</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{client.client_name}</td>
              <td>{client.bank_as_per_account_name}</td>
              <td>{client.c_name}</td>
              <td>{client.bank_account_no}</td>
              <td>{client.bank_ifsc_no}</td>
              <td>{client.bank_name}</td>
              <td>{client.bank_branch_name}</td>
              <td>{client.wire_numbers}</td>
              <td>{client.payout_amount}</td>
              <td>
                {client.first_payout_date
                  ? moment(client.first_payout_date).format("YYYY-MM-DD")
                  : "N/A"}
              </td>
              <td>
                {client.payment_due
                  ? moment(client.payment_due).format("YYYY-MM-DD")
                  : "N/A"}
              </td>
              <td>{client.country}</td>
              <td>
                <div style={{ display: "flex", gap: "10px" }}>
                  <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client)}
                  ></i>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.bank_id)}
                  ></i>
                </div>
              </td>
              <td>
                <Button
                  onClick={() => handleRevert(client.bank_id)}
                  variant="secondary"
                  size="sm"
                >
                  Revert List
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Modal to show reverted clients */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reverted Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select to revert:</h5>
          <ul>
            {revertedClients?.length > 0 ? (
              revertedClients?.map((client, index) => (
                <div key={index} className="reverted-client">
                  <input
                    type="checkbox"
                    checked={selectedRevertedClients.includes(client)}
                    onChange={() => handleSelectRevertedClient(client)}
                  />
                  <p>
                    <strong>Change Happened on: {client.r_what}</strong>
                  </p>
                  <p>
                    From: <span>{client.r_change_from}</span>, To:{" "}
                    <span>{client.r_change_to}</span>
                  </p>
                  <p>
                    <strong>User who edited:</strong>{" "}
                    <span>{client.user_name}</span>
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    <span>{moment(client.r_date).format("YYYY-MM-DD")}</span>
                  </p>
                  <p>
                    <strong>Time:</strong> <span>{client.r_time}</span>
                  </p>
                  <hr />
                </div>
              ))
            ) : (
              <p>No reverted clients available.</p>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleRevertSelected}>
            Revert Selected
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Bank;

import React, { useEffect, useState } from "react";
import "./clients.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"; // Import Modal
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Clients = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [revertedClients, setRevertedClients] = useState([]); // State for reverted clients
  const [selectedRevertedClients, setSelectedRevertedClients] = useState([]);
  useEffect(() => {
    fetchCompanies(); // Fetch companies on mount
    ClientList(); // Initial call to fetch clients
  }, []);

  const ClientList = async (companyId) => {
    try {
      const response = await axios.get(
        "https://lunarsenterprises.com:4000/aims/clients",
        {
          headers: {
            company_id: companyId, // Pass the company ID in the header
          },
        }
      );
      setList(response.data);
      setFilteredClients(response.data);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company"
      );
      if (response.data.result) {
        setCompanies(response.data.data);
      } else {
        console.error("Failed to fetch companies:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleAddUser = () => {
    navigate("/adduser");
  };

  const handleEditUser = (client) => {
    navigate("/edituser", { state: { client } });
  };

  const DeleteApi = async (clientId) => {
    const token = localStorage.getItem("token");
    // Ask for confirmation before proceeding with deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the deletion
    }

    try {
      const response = await fetch(
        `https://lunarsenterprises.com:4000/aims/clients/delete/${clientId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        alert(responseData.msg);
        ClientList(selectedCompany); // Refresh the client list after deletion
      } else {
        const errorData = await response.json();
        console.error(
          "Failed to delete client:",
          errorData.message || errorData
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = list.filter((client) =>
      client.client_name.toLowerCase().includes(value)
    );

    setFilteredClients(filtered);
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    ClientList(companyId); // Fetch clients for the selected company
  };

  // New function to fetch reverted clients
  const fetchRevertedClients = async (clientId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/client/revert/list",
        { client_id: clientId }
      );
      if (response.data.result) {
        setRevertedClients(response.data.data); // Set the reverted clients data
      } else {
        console.error(
          "Failed to fetch reverted clients:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching reverted clients:", error);
    }
  };

  // New function to handle modal display
  const handleRevert = (clientId) => {
    setShowModal(true);
    fetchRevertedClients(clientId); // Fetch reverted clients when opening the modal
  };
  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };
  // Function to revert the previous list by updating all reverted clients

  const handleSelectRevertedClient = (client) => {
    if (selectedRevertedClients.includes(client)) {
      setSelectedRevertedClients((prev) => prev.filter((c) => c !== client));
    } else {
      setSelectedRevertedClients((prev) => [...prev, client]);
    }
  };

  // New function to revert selected clients
  const handleRevertSelected = async () => {
    const token = localStorage.getItem("token");
    const r_ids = selectedRevertedClients?.map((client) => client.r_id); // Collect r_id of selected clients

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/client/reverting",
        { r_id: r_ids }, // Send r_ids as an array
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.result) {
        alert(response.data.message); // Show success message
        handleCloseModal(); // Close the modal
        ClientList(selectedCompany); // Refresh the client list
        setSelectedRevertedClients([]); // Reset selected clients
      } else {
        alert("Failed to revert clients: " + response.data.message);
      }
    } catch (error) {
      console.error("Error reverting clients:", error);
    }
  };

  //.............Export Client Api..............//

  const handleExport = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/client/sheet/export",
        {},
        {
          headers: {
            company_id: selectedCompany, // Pass selected company_id as a header
          },
        }
      );

      if (response.data.result && response.data.filepath) {
        const fileUrl = response.data.filepath;

        // Trigger file download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "sales.xlsx"); // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
      } else {
        alert("Failed to export the sales data. Please try again.");
      }
    } catch (error) {
      console.error("Error exporting sales data:", error);
      alert("An error occurred during export. Please try again.");
    }
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <button className="export-button" onClick={handleExport}>
            <i className="fas fa-upload"></i> Export
          </button>
          <button className="import-button">
            <i className="fas fa-download"></i> Import
          </button>
        </div>
        <div className="search-actions">
          <div className="company-filter">
            <label className="text1">Select Company:</label>
            <select
              value={selectedCompany}
              onChange={handleCompanyChange}
              className="company-dropdown"
            >
              <option value="">Select a company</option>
              {companies?.map((company) => (
                <option key={company.c_id} value={company.c_id}>
                  {company.c_name}
                </option>
              ))}
            </select>
          </div>
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button onClick={handleAddUser}>
            ADD CLIENT
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>CONTRACT NO</th>
            <th>COMPANY NAME</th>
            <th>NAME</th>
            <th>PHONE NUMBER</th>
            <th>EMAIL ID</th>
            <th>EMIRATES ID/PASSPORT NO:</th>
            <th>CONTRACT START DATE</th>
            <th>CONTRACT END DATE</th>
            <th>INSURANCE PREMIUM</th>
            <th>LAST EDITED</th>
            <th>ACTIONS</th>
            <th>REVERT OPTION</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients.length > 0 ? (
            filteredClients?.map((client, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{client.client_contract_number}</td>
                <td>{client.company_name}</td>
                <td>{client.client_name}</td>
                <td>{client.client_number}</td>
                <td>{client.client_email_id}</td>
                <td>{client.client_passport}</td>

                <td>
                  {client.client_contract_start_date
                    ? moment(client.client_contract_start_date).format(
                        "YYYY-MM-DD"
                      )
                    : "N/A"}
                </td>
                <td>
                  {client.client_contract_end_date
                    ? moment(client.client_contract_end_date).format(
                        "YYYY-MM-DD"
                      )
                    : "N/A"}
                </td>
                <td>{client.client_insurance_status}</td>
                <td>
                  {client.last_updated_at
                    ? moment(client.last_updated_at).format("YYYY-MM-DD")
                    : "N/A"}
                </td>

                <td>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <i
                      className="fas fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditUser(client)}
                    ></i>
                    <i
                      className="fas fa-trash"
                      style={{ cursor: "pointer" }}
                      onClick={() => DeleteApi(client.client_id)}
                    ></i>
                  </div>
                </td>
                <td>
                  <Button
                    onClick={() => handleRevert(client.client_id)}
                    variant="secondary"
                    size="sm"
                  >
                    Revert List
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="13" style={{ textAlign: "center" }}>
                No clients found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal for reverted clients */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reverted Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select to revert:</h5>
          <ul>
            {revertedClients?.length > 0 ? (
              revertedClients?.map((client, index) => (
                <div key={index} className="reverted-client">
                  <input
                    type="checkbox"
                    checked={selectedRevertedClients.includes(client)}
                    onChange={() => handleSelectRevertedClient(client)}
                  />
                  <p>
                    <strong>Change Happened on: {client.r_what}</strong>
                  </p>
                  <p>
                    From: <span>{client.r_change_from}</span>, To:{" "}
                    <span>{client.r_change_to}</span>
                  </p>
                  <p>
                    <strong>User who edited:</strong>{" "}
                    <span>{client.user_name}</span>
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    <span>{moment(client.r_date).format("YYYY-MM-DD")}</span>
                  </p>
                  <p>
                    <strong>Time:</strong> <span>{client.r_time}</span>
                  </p>
                  <hr />
                </div>
              ))
            ) : (
              <p>No reverted clients available.</p>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleRevertSelected}>
            Revert Selected
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Clients;

import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./Addcompany.scss";
import { useNavigate } from "react-router-dom";

const AddCompany = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyGst, setCompanyGst] = useState("");
  const [companyRegistration, setCompanyRegistration] = useState("");
  const [companyCategory, setCompanyCategory] = useState("");
  const [companyPerson, setCompanyPerson] = useState("");
  const [companyMail, setCompanyMail] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setCompanyLogo(file);
    } else {
      alert("Please upload a valid image file.");
      setCompanyLogo(null);
    }
  };

  const validateMobileNumber = (mobile) => {
    const regex = /^\d{10,15}$/; // Mobile number should be between 10 and 15 digits
    return regex.test(mobile);
  };

  const Add_Company = async (event) => {
    event.preventDefault();

    if (!validateMobileNumber(companyMobile)) {
      alert("Please enter a valid mobile number (10 to 15 digits including country code).");
      return;
    }

    const formData = new FormData();
    formData.append("company_logo", companyLogo);
    formData.append("company_name", companyName);
    formData.append("company_address", companyAddress);
    formData.append("company_gst", companyGst);
    formData.append("company_registration", companyRegistration);
    formData.append("company_category", companyCategory);
    formData.append("company_person", companyPerson);
    formData.append("company_mail", companyMail);
    formData.append("company_mobile", companyMobile);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/company/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/company");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Company</h2>
      <Form onSubmit={Add_Company}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formCompanyLogo">
              <Form.Label>Company Logo</Form.Label>
              <Form.Control
                type="file"
                name="company_logo"
                onChange={handleFileChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyAddress">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                type="text"
                name="company_address"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyGst">
              <Form.Label>Company GST</Form.Label>
              <Form.Control
                type="text"
                name="company_gst"
                value={companyGst}
                onChange={(e) => setCompanyGst(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyMail">
              <Form.Label>Company Email</Form.Label>
              <Form.Control
                type="email"
                name="company_mail"
                value={companyMail}
                onChange={(e) => setCompanyMail(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCompanyRegistration">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="company_registration"
                value={companyRegistration}
                onChange={(e) => setCompanyRegistration(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyCategory">
              <Form.Label>Type of Company</Form.Label>
              <Form.Control
                type="text"
                name="company_category"
                value={companyCategory}
                onChange={(e) => setCompanyCategory(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyPerson">
              <Form.Label>Responsible Person</Form.Label>
              <Form.Control
                type="text"
                name="company_person"
                value={companyPerson}
                onChange={(e) => setCompanyPerson(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCompanyMobile">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                name="company_mobile"
                value={companyMobile}
                onChange={(e) => setCompanyMobile(e.target.value)}
                required
              />
              <Form.Text className="text-muted">
                Please enter the mobile number including the country code.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <div className="submit-container">
          <Button variant="primary" type="submit" className="submit-button">
            Submit
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AddCompany;

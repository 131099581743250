import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner"; // Optional for loading state

const Cheques = () => {
  const navigate = useNavigate();
  const [clientsData, setClientsData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false); // For loading spinner

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company",
        {}
      );
      if (response.data.result) {
        setCompanyData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCheques = async (companyId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/cheque/list",
        {},
        {
          headers: {
            company_id: companyId,
          },
        }
      );

      if (response.data.result) {
        const formattedData = response.data.data?.map((client, index) => ({
          slno: index + 1,
          clientName: client.client_name,
          chequeIssuedDate: moment(client.c_issue_date).isValid()
            ? moment(client.c_issue_date).format("DD/MM/YYYY")
            : "N/A",
          chequeDate: moment(client.c_date).isValid()
            ? moment(client.c_date).format("DD/MM/YYYY")
            : "N/A",
          chequeCollectionDate: moment(client.c_collection_date).isValid()
            ? moment(client.c_collection_date).format("DD/MM/YYYY")
            : "N/A",
          amount: client.c_amount,
          typwOfCheque: client.c_type,
          chequeNumber: client.c_no,
          bankName: client.c_bank_name,
          chequeImage: client.c_image,
          c_id: client.c_id,
        }));

        setClientsData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching cheques:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchCheques();
  }, []);

  useEffect(() => {
    if (selectedCompanyId) {
      setClientsData([]);
      fetchCheques(selectedCompanyId);
    }
  }, [selectedCompanyId]);

  const handleAddCheque = () => {
    navigate("/addcheque");
  };

  const handleShowImage = (imagePath) => {
    setSelectedImage(imagePath);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  const DeleteApi = async (chequeId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/cheque/delete",
        { c_id: chequeId }
      );
      console.log(response, "responseeee");

      console.log(chequeId, "chequeee");
      if (response.data.result) {
        alert(response.data.message);
        fetchCheques(selectedCompanyId);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleDelete = (chequeId) => {
    if (window.confirm("Are you sure you want to delete this cheque?")) {
      DeleteApi(chequeId);
    }
  };

  return (
    <>
      <div className="sub-head d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <Form.Label className="me-2 mb-0">Select Company:</Form.Label>
          <Form.Select
            value={selectedCompanyId}
            onChange={(e) => setSelectedCompanyId(e.target.value)}
            style={{ width: "250px" }}
          >
            <option value="">Select Company</option>
            {companyData?.map((company) => (
              <option key={company.c_id} value={company.c_id}>
                {company.c_name}
              </option>
            ))}
          </Form.Select>
        </div>

        <Button variant="primary" onClick={handleAddCheque}>
          ADD CHEQUE <i className="fas fa-plus"></i>
        </Button>
      </div>

      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : clientsData.length === 0 ? (
        <div className="text-center">No data found</div>
      ) : (
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th>SL NO:</th>
              <th>CLIENT NAME</th>
              <th>CHECK ISSUED DATE</th>
              <th>CHEQUE DUE DATE</th>
              <th>CHEQUE COLLECTION DATE</th>
              <th>AMOUNT</th>
              <th>TYPE OF CHEQUE</th>
              <th>CHEQUE NUMBER</th>
              <th>BANK NAME</th>
              <th>VIEW DOC</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {clientsData?.map((client) => (
              <tr key={client.slno}>
                <td>{client.slno}</td>
                <td>{client.clientName}</td>
                <td>{client.chequeIssuedDate}</td>
                <td>{client.chequeDate}</td>
                <td>{client.chequeCollectionDate}</td>
                <td>{client.amount}</td>
                <td>{client.typwOfCheque}</td>
                <td>{client.chequeNumber}</td>
                <td>{client.bankName}</td>
                <td>
                  <i
                    className="fas fa-eye"
                    onClick={() => handleShowImage(client.chequeImage)}
                    style={{ cursor: "pointer" }}
                  ></i>
                </td>
                <td>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <i
                      className="fas fa-trash"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(client.c_id)}
                    ></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Cheque Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center" style={{ padding: 0 }}>
          <img
            src={`https://lunarsenterprises.com:4000/${selectedImage}`}
            alt="Cheque Document"
            style={{ width: "100%", maxHeight: "600px", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Cheques;

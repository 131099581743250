import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom"; // For getting route state
import axios from "axios";
import "./Addcompany.scss";

const Editcompany = () => {
  const location = useLocation();
  const company = location.state?.company || {}; // Get company from route state

  const [companyname, setCompanyname] = useState("");
  const [companyid, setCompanyId] = useState("");
  const [phone, setPhone] = useState("");
  const [mailId, setMailId] = useState("");
  const [registration, setRegistration] = useState("");
  const [typeofcompany, setTypeofcompany] = useState("");
  const [responsibleperson, setResponsibleperson] = useState("");
  const [bankdetails, setBankdetails] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null); // New logo to be uploaded
  const [existingLogo, setExistingLogo] = useState(""); // Existing logo
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyGst, setCompanyGst] = useState("");
  const [companyAccountNo, setCompanyAccountNo] = useState("");
  const [companyIfscCode, setCompanyIfscCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (company) {
      // Initialize form with existing company details
      setCompanyId(company.c_id);
      setCompanyname(company.c_name);
      setPhone(company.c_mobile);
      setMailId(company.c_mail);
      setRegistration(company.c_registration);
      setTypeofcompany(company.c_category);
      setResponsibleperson(company.c_person);
      setExistingLogo(company.c_logo); // Set the existing logo
      setCompanyAddress(company.c_address);
      setCompanyGst(company.c_gst);

      // Check for bank details and set account number and IFSC code
      if (company.bank && company.bank.length > 0) {
        setCompanyAccountNo(company.bank[0].bank_account_no);
        setCompanyIfscCode(company.bank[0].bank_ifsc_no);
      }
    }
  }, [company]);

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file from the file input
    if (file) {
      setCompanyLogo(file); // Update the state with the selected file
    } else {
      setCompanyLogo(null); // Reset state if no file is selected
    }
  };

  const updateCompany = async (event) => {
    event.preventDefault();

    // Validate fields
    if (
      !companyname ||
      !phone ||
      !mailId ||
      !registration ||
      !typeofcompany ||
      !responsibleperson ||
      !companyAddress ||
      !companyGst
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(mailId)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Validate phone number
    if (!/^\d{1,15}$/.test(phone)) {
      alert("Please enter a valid mobile number with up to 15 digits, including country code.");
      return;
    }

    const formData = new FormData();

    if (companyLogo) {
      formData.append("company_logo", companyLogo); // Add new logo if provided
    } else {
      formData.append("company_logo", existingLogo); // Use existing logo
    }

    formData.append("c_id", companyid);
    formData.append("c_name", companyname);
    formData.append("c_address", companyAddress);
    formData.append("c_gst", companyGst);
    formData.append("c_registration", registration);
    formData.append("c_category", typeofcompany);
    formData.append("c_person", responsibleperson);
    
    // Only append account number and IFSC code if they are provided
    if (companyAccountNo) {
      formData.append("company_account_no", companyAccountNo);
    }
    
    if (companyIfscCode) {
      formData.append("company_ifsc_code", companyIfscCode);
    }

    formData.append("c_mobile", phone);
    formData.append("c_mail", mailId);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/edit-company",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert(response.data.message);
      navigate("/company");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleClose = () => {
    navigate("/company");
  };
  

  return (
    <Container className="add-user-container">
      <div className="header">
        <h2 className="adduser">Edit Company</h2>
        <button className="close-button" onClick={handleClose}>
          X
        </button>
      </div>
      <Form onSubmit={updateCompany}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formCompanyLogo">
              <Form.Label>Company Logo</Form.Label>
              <Form.Control
                type="file"
                name="company_logo"
                onChange={handleFileChange}
              />
              {/* Display the selected file name */}
              {companyLogo && (
                <Form.Text className="text-muted">
                  Selected file: {companyLogo.name}
                </Form.Text>
              )}
              {existingLogo && (
                <Form.Text className="text-muted mt-2">
                  Existing logo path: {existingLogo}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyname"
                value={companyname}
                onChange={(e) => setCompanyname(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Company Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <Form.Text className="text-muted">
                Please keep mobile number including country code.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formMailId">
              <Form.Label>Company Email ID</Form.Label>
              <Form.Control
                type="email"
                name="mailId"
                value={mailId}
                onChange={(e) => setMailId(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formCompanyAddress">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                type="text"
                name="companyAddress"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formGst">
              <Form.Label>Company GST</Form.Label>
              <Form.Control
                type="text"
                name="companyGst"
                value={companyGst}
                onChange={(e) => setCompanyGst(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formRegistration">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="registration"
                value={registration}
                onChange={(e) => setRegistration(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTypeofcompany">
              <Form.Label>Type of Company</Form.Label>
              <Form.Control
                type="text"
                name="typeofcompany"
                value={typeofcompany}
                onChange={(e) => setTypeofcompany(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formResponsibleperson">
              <Form.Label>Responsible Person</Form.Label>
              <Form.Control
                type="text"
                name="responsibleperson"
                value={responsibleperson}
                onChange={(e) => setResponsibleperson(e.target.value)}
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="formAccountNo">
              <Form.Label>Account Number (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="companyAccountNo"
                value={companyAccountNo}
                onChange={(e) => setCompanyAccountNo(e.target.value)}
              />
            </Form.Group> */}

            {/* <Form.Group controlId="formIfscCode">
              <Form.Label>IFSC Code (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="companyIfscCode"
                value={companyIfscCode}
                onChange={(e) => setCompanyIfscCode(e.target.value)}
              />
            </Form.Group> */}
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-3">
          Update Company
        </Button>
      </Form>
    </Container>
  );
};

export default Editcompany;

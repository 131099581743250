import React, { useEffect, useState } from "react";
import "./Sales.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Sales = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [show, setShow] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [lastEditDetails, setLastEditDetails] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterAmount, setFilterAmount] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [companyList, setCompanyList] = useState([]); // Store company list
  const [selectedCompanyId, setSelectedCompanyId] = useState(""); // Store selected company ID
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [revertedClients, setRevertedClients] = useState([]); // State for reverted clients
  const [selectedRevertedSales, setSelectedRevertedSales] = useState([]);
  useEffect(() => {
    fetchCompanyList(); // Fetch companies when component mounts
  }, []);

  // Fetch sales list whenever the selectedCompanyId changes
  useEffect(() => {
    if (selectedCompanyId) {
      SalesList();
    }
  }, [selectedCompanyId]);
  useEffect(() => {
    SalesList();
  }, []);

  // Fetch company list from API
  const fetchCompanyList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company"
      );
      setCompanyList(response.data.data); // Set company data
    } catch (error) {
      console.error("Error fetching company list:", error);
    }
  };

  // Fetch sales list based on selected company ID
  const SalesList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-sales",
        {},
        {
          headers: {
            company_id: selectedCompanyId, // Pass company_id as a header
          },
        }
      );
      setList(response.data.list);
      setFilteredClients(response.data.list); // Initialize filtered clients
    } catch (error) {
      console.error("Error fetching sales list:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddUser = () => {
    navigate("/addsales");
  };

  const handleEditUser = (salesId) => {
    navigate(`/editsales/${salesId}`);
  };

  const DeleteApi = async (salesId) => {
    // Ask for confirmation before proceeding with deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the deletion
    }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/delete-sales",
        { sales_id: salesId } // Pass sales_id in the request body
      );
      if (response.status === 200) {
        alert(response.data.message);
        SalesList(); // Refresh the sales list after deletion
      } else {
        alert("Failed to delete the sales record. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-CA", options);
  };

  const handleSearchChange = (e) => {
    setFilterName(e.target.value);
    const filtered = list.filter((client) =>
      client.client_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  // New function to handle modal display
  const handleRevert = (salesId) => {
    setShowModal(true);
    fetchRevertedSales(salesId); // Fetch reverted clients when opening the modal
  };

  // Handle company selection change
  const handleCompanyChange = (e) => {
    setSelectedCompanyId(e.target.value); // Update selected company ID
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // New function to fetch reverted clients
  const fetchRevertedSales = async (salesId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/sales/revert/list",
        { sales_id: salesId }
      );
      if (response.data.result) {
        setRevertedClients(response.data.data); // Set the reverted clients data
        console.log("Revert API response:", response.data);
      } else {
        console.error(
          "Failed to fetch reverted clients:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching reverted clients:", error);
    }
  };

 

  //.............Export Sales Api..............//

  const handleExport = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/sales/sheet/export",
        {},
        {
          headers: {
            company_id: selectedCompanyId, // Pass selected company_id as a header
          },
        }
      );

      if (response.data.result && response.data.filepath) {
        const fileUrl = response.data.filepath;

        // Trigger file download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "sales.xlsx"); // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
      } else {
        alert("Failed to export the sales data. Please try again.");
      }
    } catch (error) {
      console.error("Error exporting sales data:", error);
      alert("An error occurred during export. Please try again.");
    }
  };

  //...........Download Invoice..............//

  const handleDownloadInvoice = async (salesId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/download-invoice",
        { sales_id: salesId }, // Include the sales ID in the request body
        { responseType: "blob" } // Set response type to blob for file download
      );

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${salesId}.pdf`); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
      alert("An error occurred while downloading the invoice.");
    }
  };
  const handleSelectRevertedSales = (client) => {
    if (selectedRevertedSales.includes(client)) {
      setSelectedRevertedSales((prev) => prev.filter((c) => c !== client));
    } else {
      setSelectedRevertedSales((prev) => [...prev, client]);
    }
  };
  const handleRevertSelected = async () => {
    const token = localStorage.getItem("token");
    const r_ids = selectedRevertedSales?.map((client) => client.r_id); // Collect r_id of selected clients

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/sales/reverting",
        { r_id: r_ids }, // Send r_ids as an array
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.result) {
        alert(response.data.message); // Show success message
        handleCloseModal(); // Close the modal
        SalesList(selectedCompanyId); // Refresh the client list
        setSelectedRevertedSales([]); // Reset selected clients
      } else {
        alert("Failed to revert sales: " + response.data.message);
      }
    } catch (error) {
      console.error("Error reverting sales:", error);
    }
  };
  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <button className="export-button" onClick={handleExport}>
            <i className="fas fa-upload"></i> Export
          </button>
          <button className="import-button" >
            <i className="fas fa-download"></i> Import
          </button>
        </div>

        <div className="company-filter">
          <label className="text1">Select Company:</label>
          <select
            value={selectedCompanyId}
            onChange={handleCompanyChange}
            className="company-dropdown"
          >
            <option value="">Select a company</option>
            {companyList?.map((company) => (
              <option key={company.c_id} value={company.c_id}>
                {company.c_name}
              </option>
            ))}
          </select>
        </div>
        <div className="head-icons">
          <button onClick={handleAddUser}>
            ADD SALES
            <i className="fas fa-plus"></i>
          </button>
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={filterName}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>CLIENT NAME</th>
            <th>COMPANY NAME</th>
            <th>PRODUCT DESCRIPTION</th>
            <th>UNITS</th>
            <th>DISCOUNT</th>
            <th>COST OF CONTAINER</th>
            <th>TOTAL AMOUNT</th>
            <th>DATE OF SALES</th>
            <th>SERVICE FEES</th>
            <th>MONTHLY PAYOUT AMOUNT</th>
            <th>MODE OF PAYMENT</th>
            <th>ACTIONS</th>
            <th>DOWNLOAD INVOICE</th>
            <th>REVERT OPTION</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{client.client_name}</td>
              <td>{client.c_name}</td>
              <td>{client.sales_product}</td>
              <td>{client.sales_unit}</td>
              <td>{client.sales_discount}</td>
              <td>{client.cost_of_container}</td>
              <td>{client.sales_total_amount}</td>
              <td>{formatDate(client.sales_date)}</td>
              <td>{client.service_fees}</td>
              <td>{client.sales_monthly_payment_amount}</td>
              <td>{client.mode_of_payment}</td>
              <td>
                <div style={{ display: "flex", gap: "10px" }}>
                  <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client.sales_id)}
                  ></i>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.sales_id)}
                  ></i>
                </div>
              </td>
              <td>
                <i
                  className="fas fa-download"
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleDownloadInvoice(client.sales_id)}
                ></i>
              </td>
              <td>
                <Button
                  onClick={() => handleRevert(client.sales_id)}
                  variant="secondary"
                  size="sm"
                >
                  Revert List
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for reverting previous sales */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reverted Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select to revert:</h5>
          <ul>
            {revertedClients?.length > 0 ? (
              revertedClients?.map((client, index) => (
                <div key={index} className="reverted-client">
                  <input
                    type="checkbox"
                    checked={selectedRevertedSales.includes(client)}
                    onChange={() => handleSelectRevertedSales(client)}
                  />
                  <p>
                    <strong>Change Happened on: {client.r_what}</strong>
                  </p>
                  <p>
                    From: <span>{client.r_change_from}</span>, To:{" "}
                    <span>{client.r_change_to}</span>
                  </p>
                  <p>
                    <strong>User who edited:</strong>{" "}
                    <span>{client.user_name}</span>
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    <span>{moment(client.r_date).format("YYYY-MM-DD")}</span>
                  </p>
                  <p>
                    <strong>Time:</strong> <span>{client.r_time}</span>
                  </p>
                  <hr />
                </div>
              ))
            ) : (
              <p>No reverted clients available.</p>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleRevertSelected}>
            Revert Selected
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sales;

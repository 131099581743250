import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Tesseract from "tesseract.js"; // Import Tesseract.js
import "./AddCheque.scss";
import { useNavigate } from "react-router-dom";

const AddCheque = () => {
  const [companyList, setCompanyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [chequeIssuedDate, setChequeIssuedDate] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [chequeCollectionDate, setChequeCollectionDate] = useState("");
  const [chequeAmount, setChequeAmount] = useState("");
  const [chequeType, setChequeType] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch company list
    const fetchCompanies = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4000/aims/list-company",
          {}
        );
        setCompanyList(response.data.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    // Fetch client list
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          "https://lunarsenterprises.com:4000/aims/clients",
          {
            headers: {
              company_id: selectedCompanyId,
            },
          }
        );
        setClientList(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    if (selectedCompanyId) {
      fetchClients();
    }
  }, [selectedCompanyId]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setCompanyLogo(file);
    await extractDataFromCheque(file);
  };

  const extractDataFromCheque = async (file) => {
    try {
      const {
        data: { text },
      } = await Tesseract.recognize(file, "eng", {
        logger: (m) => console.log(m), // Log progress
      });
      console.log(text); // Log extracted text

      // Parse the extracted text to find the required fields
      parseChequeData(text);
    } catch (error) {
      console.error("Error extracting data from cheque:", error);
    }
  };

  const parseChequeData = (text) => {
    // You need to implement this function to parse the text and extract dates and other information
    // This is a very basic example and might need adjustments based on cheque format
    const issuedDateMatch = text.match(/Issued Date:\s*(\d{2}-\d{2}-\d{4})/);
    const dueDateMatch = text.match(/Due Date:\s*(\d{2}-\d{2}-\d{4})/);
    const collectionDateMatch = text.match(
      /Collection Date:\s*(\d{2}-\d{2}-\d{4})/
    );
    const amountMatch = text.match(/Amount:\s*\$?(\d+(\.\d{2})?)/);
    const chequeNumberMatch = text.match(/Cheque Number:\s*(\d+)/);
    const bankNameMatch = text.match(/Bank:\s*(.+)/);

    if (issuedDateMatch) setChequeIssuedDate(issuedDateMatch[1]);
    if (dueDateMatch) setChequeDate(dueDateMatch[1]);
    if (collectionDateMatch) setChequeCollectionDate(collectionDateMatch[1]);
    if (amountMatch) setChequeAmount(amountMatch[1]);
    if (chequeNumberMatch) setChequeNumber(chequeNumberMatch[1]);
    if (bankNameMatch) setBankName(bankNameMatch[1]);
  };

  const Add_Cheque = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("client_id", selectedClientId);
    formData.append("cheque_issue_date", chequeIssuedDate);
    formData.append("cheque_date", chequeDate);
    formData.append("cheque_amount", chequeAmount);
    formData.append("cheque_type", chequeType);
    formData.append("cheque_no", chequeNumber);
    formData.append("bank_name", bankName);
    formData.append("cheque_collection_date", chequeCollectionDate);
    formData.append("image", companyLogo);

    try {
      setLoading(true);
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/add-cheque",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            company_id: selectedCompanyId,
          },
        }
      );

      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/cheques");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Cheque</h2>
      <Form onSubmit={Add_Cheque}>
        <Row>
          <Col md={6}>
            {/* Company Dropdown */}
            <Form.Group controlId="formSelectCompany">
              <Form.Label>Select Company</Form.Label>
              <Form.Control
                as="select"
                value={selectedCompanyId}
                onChange={(e) => setSelectedCompanyId(e.target.value)}
                required
              >
                <option value="">Select a Company</option>
                {companyList?.map((company) => (
                  <option key={company.c_id} value={company.c_id}>
                    {company.c_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Client Dropdown */}
            <Form.Group controlId="formSelectClient">
              <Form.Label>Select Client</Form.Label>
              <Form.Control
                as="select"
                value={selectedClientId}
                onChange={(e) => setSelectedClientId(e.target.value)}
                required
              >
                <option value="">Select a Client</option>
                {clientList?.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Cheque Document Upload */}
            <Form.Group controlId="formCompanyLogo">
              <Form.Label>Cheque Document</Form.Label>
              <Form.Control
                type="file"
                name="company_logo"
                onChange={handleFileChange}
                required
              />
            </Form.Group>

            {/* Cheque Issued Date */}
            <Form.Group controlId="formChequeIssuedDate">
              <Form.Label>Cheque Issued Date</Form.Label>
              <Form.Control
                type="date"
                name="cheque_issue_date"
                value={chequeIssuedDate}
                onChange={(e) => setChequeIssuedDate(e.target.value)}
                required
              />
            </Form.Group>

            {/* Cheque Due Date */}
            <Form.Group controlId="formChequeDate">
              <Form.Label>Cheque Due Date</Form.Label>
              <Form.Control
                type="date"
                name="cheque_date"
                value={chequeDate}
                onChange={(e) => setChequeDate(e.target.value)}
                required
              />
            </Form.Group>

            {/* Cheque Collection Date */}
            <Form.Group controlId="formChequeCollectionDate">
              <Form.Label>Cheque Collection Date</Form.Label>
              <Form.Control
                type="date"
                name="cheque_collection_date"
                value={chequeCollectionDate}
                onChange={(e) => setChequeCollectionDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            {/* Amount Field */}
            <Form.Group controlId="formChequeAmount">
              <Form.Label>Cheque Amount</Form.Label>
              <Form.Control
                type="text"
                name="cheque_amount"
                value={chequeAmount}
                onChange={(e) => setChequeAmount(e.target.value)}
                required
              />
            </Form.Group>

            {/* Cheque Number Field */}
            <Form.Group controlId="formChequeNumber">
              <Form.Label>Cheque Number</Form.Label>
              <Form.Control
                type="text"
                name="cheque_no"
                value={chequeNumber}
                onChange={(e) => setChequeNumber(e.target.value)}
                required
              />
            </Form.Group>

            {/* Bank Name Field */}
            <Form.Group controlId="formBankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="bank_name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                required
              />
            </Form.Group>

            {/* Cheque Type Field */}
            <Form.Group controlId="formChequeType">
              <Form.Label>Cheque Type</Form.Label>
              <Form.Control
                type="text"
                name="cheque_type"
                value={chequeType}
                onChange={(e) => setChequeType(e.target.value)}
                required
              />
            </Form.Group>

            <Button
              className="mt-3"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Adding..." : "Add Cheque"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddCheque;

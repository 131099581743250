import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./AddSales.scss";
import { useNavigate } from "react-router-dom";

const AddSales = () => {
  const [clientId, setClientId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [products, setProducts] = useState("");
  const [unit, setUnit] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [dateOfSales, setDateOfSales] = useState("");
  const [monthlyPayoutAmount, setMonthlyPayoutAmount] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState(""); // New state for mode of payment
  const [serviceFees, setServiceFees] = useState(""); // New state for service fees
  const [costOfContainer, setCostOfContainer] = useState(""); // New state for cost of container
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    DropdownApi();
  }, []);

  // Fetch clients and companies from the API
  const DropdownApi = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/dropdown",
        {}
      );
      if (response.data.result) {
        setClients(response.data.clientlist);
        setCompanies(response.data.companylist);
      } else {
        alert("Failed to fetch dropdown data");
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      // alert("An error occurred while fetching data. Please try again.");
    }
  };

  // Add Sales API call
  const Add_Sales = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/add-sales",
        {
          client_id: clientId,
          product: products,
          unit: unit,
          discount: discount,
          total_amount: totalAmount,
          sales_date: dateOfSales,
          monthly_payment_amount: monthlyPayoutAmount,
          mode_of_payment: modeOfPayment, // New field
          service_fees: serviceFees, // New field
          cost_of_container: costOfContainer, // New field
        },
        {
          headers: {
            company_id: companyId, // Adding company_id as a header
          },
        }
      );

      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/sales");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Sales</h2>
      <Form onSubmit={Add_Sales}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                as="select"
                name="clientId"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                required
              >
                <option value="">Select Client</option>
                {clients?.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                as="select"
                name="companyId"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
              >
                <option value="">Select Company</option>
                {companies?.map((company) => (
                  <option key={company.c_id} value={company.c_id}>
                    {company.c_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formProducts" className="mb-4">
              <Form.Label>Product Description</Form.Label>
              <Form.Control
                type="text"
                name="products"
                value={products}
                onChange={(e) => setProducts(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formUnit" className="mb-4">
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="text"
                name="unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDiscount" className="mb-4">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="text"
                name="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formTotalAmount" className="mb-4">
              <Form.Label>Total Amount</Form.Label>
              <Form.Control
                type="text"
                name="totalAmount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDateOfSales" className="mb-4">
              <Form.Label>Date of Sales</Form.Label>
              <Form.Control
                type="date"
                name="dateOfSales"
                value={dateOfSales}
                onChange={(e) => setDateOfSales(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formMonthlyPayoutAmount" className="mb-4">
              <Form.Label>Monthly Payout Amount</Form.Label>
              <Form.Control
                type="text"
                name="monthlyPayoutAmount"
                value={monthlyPayoutAmount}
                onChange={(e) => setMonthlyPayoutAmount(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCostOfContainer" className="mb-4">
              <Form.Label>Cost of Container</Form.Label>
              <Form.Control
                type="text"
                name="costOfContainer"
                value={costOfContainer}
                onChange={(e) => setCostOfContainer(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formServiceFees" className="mb-4">
              <Form.Label>Service Fees</Form.Label>
              <Form.Control
                type="text"
                name="serviceFees"
                value={serviceFees}
                onChange={(e) => setServiceFees(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formModeOfPayment" className="mb-4">
              <Form.Label>Mode of Payment</Form.Label>

              <Form.Control
                as="select"
                name="modeOfPayment"
                value={modeOfPayment}
                onChange={(e) => setModeOfPayment(e.target.value)}
                required
              >
                <option value="">Select Payment Mode</option>
                <option value="Cash">Cash</option>
                <option value="Card">Credit/Debit Card</option>
                <option value="UPI">UPI</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-4">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default AddSales;

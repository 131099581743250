import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Select from "react-select"; // Import React Select
import "./Editbank.scss";
import { useNavigate, useLocation } from "react-router-dom"; // useLocation to access passed data

const EditBank = () => {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [wireNo, setWireNo] = useState("");
  const [insuranceAmount, setInsuranceAmount] = useState("");
  const [firstPayout, setFirstPayout] = useState("");
  const [due, setDue] = useState("");
  const [country, setCountry] = useState("");
  const [payoutAmount, setPayoutAmount] = useState("");

  const [countries, setCountries] = useState([]); // State for countries

  const navigate = useNavigate();
  const location = useLocation(); // Get data passed from the previous page
  const bankData = location.state?.client; // Access passed bank data

  const countryOptions = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AG", label: "Antigua and Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" }, // Gulf Country
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
    { value: "BA", label: "Bosnia and Herzegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "IO", label: "British Indian Ocean Territory" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cabo Verde" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CD", label: "Congo, Democratic Republic of the" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "HR", label: "Croatia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curaçao" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "SZ", label: "Eswatini" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "TF", label: "French Southern Territories" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HM", label: "Heard Island and McDonald Islands" },
    { value: "VA", label: "Holy See" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Iran" }, // Gulf Country
    { value: "IQ", label: "Iraq" }, // Gulf Country
    { value: "IE", label: "Ireland" },
    { value: "IM", label: "Isle of Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordan" }, // Gulf Country
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Korea, Democratic People's Republic of" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" }, // Gulf Country
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LA", label: "Lao People's Democratic Republic" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" }, // Gulf Country
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macao" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "FM", label: "Micronesia" },
    { value: "MD", label: "Moldova" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" }, // Gulf Country
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestine" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" }, // Gulf Country
    { value: "RE", label: "Réunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "BL", label: "Saint Barthélemy" },
    { value: "SH", label: "Saint Helena" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "MF", label: "Saint Martin" },
    { value: "PM", label: "Saint Pierre and Miquelon" },
    { value: "VC", label: "Saint Vincent and the Grenadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" }, // Gulf Country
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SX", label: "Sint Maarten" },
    { value: "SK", label: "Slovakia" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "GS", label: "South Georgia and the South Sandwich Islands" },
    { value: "SS", label: "South Sudan" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SJ", label: "Svalbard and Jan Mayen" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" }, // Gulf Country
    { value: "TW", label: "Taiwan" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania" },
    { value: "TH", label: "Thailand" },
    { value: "TL", label: "Timor-Leste" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" }, // Gulf Country
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "WF", label: "Wallis and Futuna" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" }, // Gulf Country
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ];
  // Fetch the list of countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryNames = response.data?.map(
          (country) => country.name.common
        );
        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();

    // Populate the form with existing bank data when the component loads
    if (bankData) {
      setBankName(bankData.bank_name || "");
      setBranchName(bankData.bank_branch_name || "");
      setAccountNo(bankData.bank_account_no || "");
      setIfscCode(bankData.bank_ifsc_no || "");
      setAccountHolderName(bankData.bank_as_per_account_name || "");
      setWireNo(bankData.wire_numbers || "");
      setInsuranceAmount(bankData.insurance_amount || "");
      setPayoutAmount(bankData.payout_amount || ""); // Set payout amount

      // Ensure dates are in the 'YYYY-MM-DD' format
      setFirstPayout(formatDate(bankData.first_payout_date));
      setDue(formatDate(bankData.payment_due));
      setCountry(bankData.country || ""); // Set country
    }
  }, [bankData]);

  // Format the date to 'YYYY-MM-DD' if necessary
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // Ensure two digits
    const day = `0${date.getDate()}`.slice(-2); // Ensure two digits
    return `${year}-${month}-${day}`;
  };

  // Handle form submission for editing bank details
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const requestBody = {
      bank_id: bankData?.bank_id,
      bank_user_id: null, // You can set the actual user ID here
      bank_name: bankName,
      bank_branch_name: branchName,
      bank_account_no: accountNo,
      bank_ifsc_no: ifscCode,
      bank_as_per_account_name: accountHolderName,
      wire_numbers: wireNo,
      insurance_amount: insuranceAmount,
      first_payout_date: firstPayout,
      payment_due: due,
      country: country, // Include country
      payout_amount: payoutAmount, // Include payout amount
    };
  
    // Set the headers
    const headers = {
      role: "superadmin",
    };
  
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/edit-bank",
        requestBody,
        { headers } // Include headers in the request
      );
  
      if (response.status === 200) {
        alert(response.data.message);
        navigate("/bank"); // Navigate back to the list page after successful edit
      } else {
        alert("Failed to update bank details. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  const handleClose = () => {
    navigate("/bank");
  };

  return (
    <Container className="add-user-container">
      <div className="header">
        <h2 className="adduser">Edit Bank</h2>
        <button className="close-button" onClick={handleClose}>
          X
        </button>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formBankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBranchName">
              <Form.Label>Branch Name</Form.Label>
              <Form.Control
                type="text"
                name="branchName"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAccountNo">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                name="accountNo"
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formIfscCode">
              <Form.Label>IBAN/IFSC/ROUTING NO</Form.Label>
              <Form.Control
                type="text"
                name="ifscCode"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formAccountHolderName">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                type="text"
                name="accountHolderName"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formWireNo">
              <Form.Label>Wire Number</Form.Label>
              <Form.Control
                type="text"
                name="wireNo"
                value={wireNo}
                onChange={(e) => setWireNo(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formInsuranceAmount">
              <Form.Label>Insurance Amount</Form.Label>
              <Form.Control
                type="number"
                name="insuranceAmount"
                value={insuranceAmount}
                onChange={(e) => setInsuranceAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFirstPayout">
              <Form.Label>First Payout Date</Form.Label>
              <Form.Control
                type="date"
                name="firstPayout"
                value={firstPayout}
                onChange={(e) => setFirstPayout(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDue">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                name="due"
                value={due}
                onChange={(e) => setDue(e.target.value)}
              />
            </Form.Group>

            {/* New Country Dropdown */}

            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Select
                options={countryOptions}
                value={countryOptions.find(
                  (option) => option.value === country
                )} // Set selected country
                onChange={(option) => setCountry(option.value)} // Get selected value
                placeholder="Select a country"
              />
            </Form.Group>

            {/* New Payout Amount Field */}
            <Form.Group controlId="formPayoutAmount">
              <Form.Label>Payout Amount</Form.Label>
              <Form.Control
                type="number"
                name="payoutAmount"
                value={payoutAmount}
                onChange={(e) => setPayoutAmount(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-4">
          Update
        </Button>
      </Form>
    </Container>
  );
};

export default EditBank;

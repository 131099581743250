import React, { useState, useEffect } from "react";
import "./Activity.scss";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import { Modal } from "react-bootstrap";

const Activity = () => {
  const [status, setStatus] = useState(""); // No default status
  const [activityData, setActivityData] = useState([]); // For all activity data
  const [companyList, setCompanyList] = useState([]); // State to store company list
  const [selectedCompany, setSelectedCompany] = useState(""); // State for selected company
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(""); // Start date filter
  const [toDate, setToDate] = useState(""); // End date filter
  const [showModal, setShowModal] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Fetch company list from the API
  const fetchCompanyList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company",
        {}
      );
      if (response.data.result) {
        setCompanyList(response.data.data); // Set the fetched company list
      }
    } catch (error) {
      console.error("Error fetching company list:", error);
    }
  };

  // Fetch activity logs from the API based on status, date range, and selected company
  const fetchActivityLogs = async (selectedStatus, from, to, companyId) => {
    setIsLoading(true);
    try {
      const headers = {};
      if (companyId) {
        headers.company_id = companyId; // Include the selected company's ID if provided
      }

      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-activity",
        {
          from_date: from || undefined, // Send start date if available
          to_date: to || undefined, // Send end date if available
        },
        { headers }
      );

      if (response.data.result) {
        // If no status is selected, display all available activity data
        if (!selectedStatus) {
          setActivityData([
            ...(response.data.client || []),
            ...(response.data.sales || []),
            ...(response.data.bank || []),
          ]);
        } else {
          // Filter data by status
          switch (selectedStatus) {
            case "client":
              setActivityData(response.data.client || []); // Set client data
              break;
            case "sales":
              setActivityData(response.data.sales || []); // Set sales data
              break;
            case "bank":
              setActivityData(response.data.bank || []); // Set bank data
              break;
            default:
              setActivityData([]);
          }
        }
      } else {
        setActivityData([]);
      }
    } catch (error) {
      console.error("Error fetching activity logs:", error);
      setActivityData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle dropdown change for status
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    fetchActivityLogs(newStatus, fromDate, toDate, selectedCompany); // Fetch data when status changes
  };

  // Handle company dropdown change
  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    fetchActivityLogs(status, fromDate, toDate, companyId); // Fetch data when company changes
  };

  // Handle date change and fetch new data
  const handleDateChange = () => {
    if (fromDate && toDate) {
      fetchActivityLogs(status, fromDate, toDate, selectedCompany); // Fetch data when date range changes
    } else {
      console.log("Please select both a 'From' and 'To' date.");
    }
  };

  useEffect(() => {
    // Fetch all activity data when the page loads, without any filters
    fetchActivityLogs("", fromDate, toDate, selectedCompany);
    // Fetch company list
    fetchCompanyList();
  }, []);

  // Render table headers based on the status
  const renderTableHeaders = () => {
    switch (status) {
      case "client":
      case "sales":
      case "bank":
        return (
          <tr>
            <th>S.NO</th>
            <th>CHANGE HAPPENS</th> {/* New header for "Change Happens" */}
            <th>WHAT CHANGED</th>
            <th>CHANGE FROM</th>
            <th>CHANGE TO</th>
            <th>DATE</th>
            <th>TIME</th>
          </tr>
        );
      default:
        return null;
    }
  };

  // Render table rows based on the status
  const renderTableRows = () => {
    return activityData?.map((activity, index) => {
      // Determine where the change happens based on the structure of your data
      let changeHappens = "";
      if (status === "client" || activity.r_status.includes("client")) {
        changeHappens = "Client";
      } else if (status === "sales" || activity.r_status.includes("sales")) {
        changeHappens = "Sales";
      } else if (status === "bank" || activity.r_status.includes("bank")) {
        changeHappens = "Bank";
      }

      const formatChangeValue = (value) => {
        if (moment(value, moment.ISO_8601, true).isValid()) {
          // If the value is a valid date, format it to YYYY-MM-DD
          return moment(value).format("YYYY-MM-DD");
        }
        return value; // If it's not a date, return as is
      };

      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{changeHappens}</td>
          <td>{activity.r_what}</td>
          <td>{formatChangeValue(activity.r_change_from)}</td>{" "}
          {/* Format if date */}
          <td>{formatChangeValue(activity.r_change_to)}</td>{" "}
          {/* Format if date */}
          <td>{moment(activity.r_date).format("YYYY-MM-DD")}</td>
          <td>{activity.r_time}</td>
        </tr>
      );
    });
  };

  return (
    <div className="activity-page">
      <h3>Activity Log</h3>

      <div className="filters">
        <select value={status} onChange={handleStatusChange}>
          <option value="">All</option>
          <option value="client">Client</option>
          <option value="sales">Sales</option>
          <option value="bank">Bank</option>
        </select>

        <select value={selectedCompany} onChange={handleCompanyChange}>
          <option value="">Select Company</option>
          {companyList?.map((company) => (
            <option key={company.c_id} value={company.c_id}>
              {company.c_name}
            </option>
          ))}
        </select>

        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          onBlur={handleDateChange} // Fetch logs on date change
        />
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          onBlur={handleDateChange} // Fetch logs on date change
        />
      </div>

      <div className="table-container">
        <Table striped bordered hover>
          <thead>{renderTableHeaders()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </Table>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Bank Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Account Number:</strong> {selectedBankDetails.account_no}
            </p>
            <p>
              <strong>IFSC Code:</strong> {selectedBankDetails.ifsc_code}
            </p>
            <p>
              <strong>Bank Name:</strong> {selectedBankDetails.bank_name}
            </p>
            <p>
              <strong>Branch:</strong> {selectedBankDetails.branch_name}
            </p>
          </Modal.Body>
        </Modal>

        <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
          <Modal.Body>
            <img src={selectedImage} alt="Selected Activity" />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Activity;

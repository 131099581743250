import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AddUser.scss";

const AddUser = () => {
  const [contractNum, setContractNum] = useState("");
  const [clientName, setClientName] = useState("");
  const [companyid, setCompanyid] = useState("");
  const [phone, setPhone] = useState("");
  const [mailId, setMailId] = useState("");
  const [passportId, setPassportId] = useState("");
  const [contractStartDate, setContractStartDate] = useState("");
  const [contractEndDate, setContractEndDate] = useState("");
  const [insurancePremium, setInsurancePremium] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const navigate = useNavigate();

  // Fetch the company list when component mounts
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4000/aims/list-company",
          {}
        );
        setCompanyList(response.data.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const Add_Client = async (event) => {
    event.preventDefault();

    // Validate mobile number length
    if (phone.length > 15) {
      alert("Mobile number should be limited to 15 digits including country code.");
      return;
    }

    // Validate contract start and end dates
    if (new Date(contractStartDate) > new Date(contractEndDate)) {
      alert("Contract start date should not be greater than contract end date.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/client/add",
        {
          client_contract_number: contractNum,
          client_name: clientName,
          client_number: phone,
          client_email_id: mailId,
          client_passport: passportId,
          client_contract_start_date: contractStartDate,
          client_contract_end_date: contractEndDate,
          client_insurance_status: insurancePremium,
        },
        {
          headers: {
            company_id: companyid,  // Pass company_id as a header
          },
        }
      );

      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/clients");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Client</h2>
      <Form onSubmit={Add_Client}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formContractNum">
              <Form.Label>Contract Number</Form.Label>
              <Form.Control
                type="text"
                name="contractNum"
                value={contractNum}
                onChange={(e) => setContractNum(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                name="clientName"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                required
              />
            </Form.Group>

            {/* Dropdown for Company ID */}
            <Form.Group controlId="formCompanyId">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                value={companyid}
                onChange={(e) => setCompanyid(e.target.value)}
                required
              >
                <option value="">Select a company</option>
                {companyList?.map((company) => (
                  <option key={company.c_id} value={company.c_id}>
                    {company.c_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <Form.Text className="text-muted">
                Please keep the mobile number including country code.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formMailId">
              <Form.Label>Mail ID</Form.Label>
              <Form.Control
                type="email"
                name="mailId"
                value={mailId}
                onChange={(e) => setMailId(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmiratesId">
              <Form.Label>Emirates ID/Passport ID</Form.Label>
              <Form.Control
                type="text"
                name="emiratesId"
                value={passportId}
                onChange={(e) => setPassportId(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContractStartDate">
              <Form.Label>Contract Start Date</Form.Label>
              <Form.Control
                type="date"
                name="contractStartDate"
                value={contractStartDate}
                onChange={(e) => setContractStartDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContractEndDate">
              <Form.Label>Contract End Date</Form.Label>
              <Form.Control
                type="date"
                name="contractEndDate"
                value={contractEndDate}
                onChange={(e) => setContractEndDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formInsurancePremium">
              <Form.Label>Insurance Premium</Form.Label>
              <Form.Control
                type="text"
                name="insurancePremium"
                value={insurancePremium}
                onChange={(e) => setInsurancePremium(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="submit-container">
          <Button variant="primary" type="submit" className="submit-button">
            Submit
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AddUser;

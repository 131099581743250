import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Editclient.scss";
import { FaTimes } from "react-icons/fa"; // Import the close icon from react-icons

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { client } = location.state || {};

  const [formData, setFormData] = useState({
    contractNum: "",
    clientName: "",
    project: "",
    phone: "",
    mailId: "",
    emiratesId: "",
    contractStartDate: "",
    contractEndDate: "",
    insurancePremium: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (client) {
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      setFormData({
        contractNum: client.client_contract_number || "",
        clientName: client.client_name || "",
        project: client.project || "",
        phone: client.client_number || "",
        mailId: client.client_email_id || "",
        emiratesId: client.client_passport || "",
        contractStartDate: client.client_contract_start_date
          ? formatDate(client.client_contract_start_date)
          : "",
        contractEndDate: client.client_contract_end_date
          ? formatDate(client.client_contract_end_date)
          : "",
        insurancePremium: client.client_insurance_status || "",
      });
    }
  }, [client]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const {
      contractNum,
      clientName,
      phone,
      mailId,
      emiratesId,
      contractStartDate,
      contractEndDate,
      insurancePremium,
    } = formData;

    if (
      !contractNum ||
      !clientName ||
      !phone ||
      !mailId ||
      !emiratesId ||
      !contractStartDate ||
      !contractEndDate ||
      !insurancePremium
    ) {
      setError("All fields are required.");
      return false;
    }

    if (!validateEmail(mailId)) {
      setError("Please enter a valid email address.");
      return false;
    }

    // Ensure phone is a string and validate its format
    const phoneString = String(phone);
    if (
      !/^[\d\s-]+$/.test(phoneString) ||
      phoneString.replace(/\D/g, "").length > 15
    ) {
      setError(
        "Mobile number should be limited to 15 digits including country code."
      );
      return false;
    }

    // Validate that the start date is not greater than the end date
    if (new Date(contractStartDate) > new Date(contractEndDate)) {
      setError("Contract Start Date cannot be greater than Contract End Date.");
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        `https://lunarsenterprises.com:4000/aims/clients/update/${client.client_id}`,
        {
          client_id: client.client_id,
          client_contract_number: formData.contractNum,
          client_name: formData.clientName,
          client_number: formData.phone,
          client_email_id: formData.mailId,
          client_passport: formData.emiratesId,
          client_contract_start_date: formData.contractStartDate,
          client_contract_end_date: formData.contractEndDate,
          client_insurance_status: formData.insurancePremium,
        },
        {
          headers: {
            role: "superadmin", // Add the role header here
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.msg);
        setTimeout(
          () =>
            navigate("/clients", { state: { updatedClient: response.data } }),
          2000
        );
      }
    } catch (error) {
      console.error("Error updating client:", error);
      setError("Failed to update client. Please try again.");
    }
  };

  const handleClose = () => {
    navigate("/clients"); // Navigate back to the clients page or the previous page
  };

  return (
    <Container className="add-user-container">
      <div className="header">
        <h2 className="adduser">Edit Client</h2>
        <button className="close-button" onClick={handleClose}>
          <FaTimes />
        </button>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formContractNum">
              <Form.Label>Contract Number</Form.Label>
              <Form.Control
                type="text"
                name="contractNum"
                value={formData.contractNum}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <Form.Text className="text-muted">
                Please keep mobile number including country code.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formMailId">
              <Form.Label>Mail ID</Form.Label>
              <Form.Control
                type="email"
                name="mailId"
                value={formData.mailId}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmiratesId">
              <Form.Label>Emirates ID/Passport ID</Form.Label>
              <Form.Control
                type="text"
                name="emiratesId"
                value={formData.emiratesId}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContractStartDate">
              <Form.Label>Contract Start Date</Form.Label>
              <Form.Control
                type="date"
                name="contractStartDate"
                value={formData.contractStartDate}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContractEndDate">
              <Form.Label>Contract End Date</Form.Label>
              <Form.Control
                type="date"
                name="contractEndDate"
                value={formData.contractEndDate}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formInsurancePremium">
              <Form.Label>Insurance Premium</Form.Label>
              <Form.Control
                type="text"
                name="insurancePremium"
                value={formData.insurancePremium}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="mt-4">
          Update
        </Button>
      </Form>
    </Container>
  );
};

export default EditUser;

import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select"; // Import React Select
import "./AddBank.scss";

const AddBank = () => {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [wireNo, setWireNo] = useState("");
  const [insuranceAmount, setInsuranceAmount] = useState("");
  const [firstPayout, setFirstPayout] = useState("");
  const [due, setDue] = useState("");
  const [payoutAmount, setPayoutAmount] = useState(""); // New state for payout amount
  const [country, setCountry] = useState(""); // New state for country
  const [companyList, setCompanyList] = useState([]); // State to store company list
  const [selectedCompany, setSelectedCompany] = useState(""); // State to store selected company ID
  const [clientList, setClientList] = useState([]); // State to store client list
  const [selectedClient, setSelectedClient] = useState(null); // State to store selected client
  const navigate = useNavigate();

  // Fetch company list
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4000/aims/list-company",
          {}
        );
        if (response.data.result) {
          setCompanyList(response.data.data); // Set the company list to state
        } else {
          alert("Failed to retrieve companies.");
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
        alert("An error occurred while fetching the company list.");
      }
    };

    fetchCompanies();
  }, []);

  // Fetch clients when a company is selected
  useEffect(() => {
    const fetchClients = async () => {
      if (!selectedCompany) return; // If no company is selected, do not fetch clients

      try {
        const response = await axios.get(
          "https://lunarsenterprises.com:4000/aims/clients",
          {
            headers: {
              company_id: selectedCompany, // Send selected company ID in headers
            },
          }
        );
        setClientList(
          response.data?.map((client) => ({
            value: client.client_id,
            label: client.client_name,
          }))
        );
      } catch (error) {
        console.error("Error fetching clients:", error);
        alert("An error occurred while fetching the client list.");
      }
    };

    fetchClients();
  }, [selectedCompany]);

  // Handle bank addition
  const Add_Bank = async (event) => {
    event.preventDefault();

    // Ensure a client is selected before proceeding
    if (!selectedClient || !selectedClient.value) {
      alert("Please select a client.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/add-bank",
        {
          bank_name: bankName,
          branch_name: branchName,
          account_no: accountNo,
          ifsc_code: ifscCode,
          account_holder_name: accountHolderName,
          wire_no: wireNo,
          insurance_amount: insuranceAmount,
          first_payout: firstPayout,
          country: country,
          payout_amount: payoutAmount,
          due: due,
        },
        {
          headers: {
            company_id: selectedCompany, // Pass selected company ID in the header
            user_id: selectedClient.value, // Pass the selected client's ID as user_id
          },
        }
      );

      alert(response.data.message);
      navigate("/bank");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // Static list of countries (can replace with an API call if necessary)
  const countryOptions = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AG", label: "Antigua and Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" }, // Gulf Country
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
    { value: "BA", label: "Bosnia and Herzegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "IO", label: "British Indian Ocean Territory" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cabo Verde" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CD", label: "Congo, Democratic Republic of the" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "HR", label: "Croatia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curaçao" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "SZ", label: "Eswatini" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "TF", label: "French Southern Territories" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HM", label: "Heard Island and McDonald Islands" },
    { value: "VA", label: "Holy See" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Iran" }, // Gulf Country
    { value: "IQ", label: "Iraq" }, // Gulf Country
    { value: "IE", label: "Ireland" },
    { value: "IM", label: "Isle of Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordan" }, // Gulf Country
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Korea, Democratic People's Republic of" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" }, // Gulf Country
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LA", label: "Lao People's Democratic Republic" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" }, // Gulf Country
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macao" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "FM", label: "Micronesia" },
    { value: "MD", label: "Moldova" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" }, // Gulf Country
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestine" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" }, // Gulf Country
    { value: "RE", label: "Réunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "BL", label: "Saint Barthélemy" },
    { value: "SH", label: "Saint Helena" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "MF", label: "Saint Martin" },
    { value: "PM", label: "Saint Pierre and Miquelon" },
    { value: "VC", label: "Saint Vincent and the Grenadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" }, // Gulf Country
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SX", label: "Sint Maarten" },
    { value: "SK", label: "Slovakia" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "GS", label: "South Georgia and the South Sandwich Islands" },
    { value: "SS", label: "South Sudan" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SJ", label: "Svalbard and Jan Mayen" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" }, // Gulf Country
    { value: "TW", label: "Taiwan" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania" },
    { value: "TH", label: "Thailand" },
    { value: "TL", label: "Timor-Leste" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" }, // Gulf Country
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "WF", label: "Wallis and Futuna" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" }, // Gulf Country
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ];

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Bank</h2>
      <Form onSubmit={Add_Bank}>
        <Row>
          <Col md={6}>
            {/* Company Dropdown */}
            <Form.Group controlId="formCompanySelect">
              <Form.Label>Select Company</Form.Label>
              <Form.Control
                as="select"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                required
              >
                <option value="">Select a company</option>
                {companyList?.map((company) => (
                  <option key={company.c_id} value={company.c_id}>
                    {company.c_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Client Dropdown with Search */}
            <Form.Group controlId="formClientSelect">
              <Form.Label>Select Client</Form.Label>
              <Select
                options={clientList}
                value={selectedClient}
                onChange={setSelectedClient}
                placeholder="Select a client"
                isSearchable // Enables search functionality within the dropdown
              />
            </Form.Group>

            {/* Other Form Fields */}
            <Form.Group controlId="formBankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBranchName">
              <Form.Label>Branch Name</Form.Label>
              <Form.Control
                type="text"
                name="branchName"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAccountNo">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                name="accountNo"
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formIfscCode">
              <Form.Label>IBAN/IFSC/ROUTING NO</Form.Label>
              <Form.Control
                type="text"
                name="ifscCode"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formAccountHolderName">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                type="text"
                name="accountHolderName"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formWireNo">
              <Form.Label>Wire Number</Form.Label>
              <Form.Control
                type="text"
                name="wireNo"
                value={wireNo}
                onChange={(e) => setWireNo(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formInsuranceAmount">
              <Form.Label>Insurance Amount</Form.Label>
              <Form.Control
                type="number"
                name="insuranceAmount"
                value={insuranceAmount}
                onChange={(e) => setInsuranceAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFirstPayout">
              <Form.Label>First Payout Date</Form.Label>
              <Form.Control
                type="date"
                name="firstPayout"
                value={firstPayout}
                onChange={(e) => setFirstPayout(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Select
                options={countryOptions}
                value={countryOptions.find(
                  (option) => option.value === country
                )} // Set selected country
                onChange={(option) => setCountry(option.value)} // Get selected value
                placeholder="Select a country"
              />
            </Form.Group>

            <Form.Group controlId="formPayoutAmount">
              <Form.Label>Payout Amount</Form.Label>
              <Form.Control
                type="number"
                name="payoutAmount"
                value={payoutAmount}
                onChange={(e) => setPayoutAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDue">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                name="due"
                value={due}
                onChange={(e) => setDue(e.target.value)}
              />
            </Form.Group>

            <Button type="submit" className="mt-3">
              Add Bank
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddBank;

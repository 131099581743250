import React, { useState, useEffect } from "react";
import "./Email.scss";
import Modal from "./Modal"; // Import the modal component

const Paragraph = ({ content, title, onSelect, isSelected, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onEdit(editedContent); // Pass the edited content to the parent
  };

  const handleChange = (e) => {
    setEditedContent(e.target.value);
  };

  return (
    <div className="formates">
      <div className="formate-heading">
        <h6>{title}</h6> {/* Display the title passed as a prop */}
        <input type="checkbox" onChange={onSelect} checked={isSelected} />
      </div>

      <div className="para-edit">
        {isEditing ? (
          <textarea value={editedContent} onChange={handleChange} />
        ) : (
          <p>{editedContent}</p>
        )}
        <i className="fas fa-edit" onClick={handleEditClick}></i>
        {isEditing && <button onClick={handleSaveClick}>Save</button>}
      </div>
    </div>
  );
};

const Email = () => {
  const [formats] = useState([
    `Good Morning Mr./Ms. [Recipient's Name],\n\nI hope you are doing well.\n\nOn behalf of the entire team at Legend Maritime Cargo (LMC), we want to extend a warm welcome to you! ...`,
    `Good Morning Mr./Ms.,\n\nHope you are doing great.\n\nPlease find the attached link to the CRM Portal as we have granted you access to the CRM. ...`,
    `Good Morning,\n\nHope you are doing great.\n\nWe appreciate your ongoing collaboration and would like to thank you for choosing our services. ...`,
    `Good Morning Mr./Ms.,\n\nI hope this email finds you well.\n\nI wanted to take a moment to extend my heartfelt congratulations on reaching the one-year milestone with Legend Maritime Cargo (LMC). ...`,
  ]);

  const titles = [
    "WARM WELCOME TO [Company Name]",
    "CRM LINK READY",
    "REQUEST BANK DETAILS",
    "Congratulations on your One-Year Anniversary with [Company Name]",
  ];

  const [selectedFormats, setSelectedFormats] = useState([]);
  const [editedContents, setEditedContents] = useState(formats); // State to keep track of edited contents
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]); // State to store selected companies
  const [companyList, setCompanyList] = useState([]); // State to store company list
  const [clientList, setClientList] = useState([]); // State to store fetched clients
  const [selectedClients, setSelectedClients] = useState([]); // State to store selected clients
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);

  // Fetch company list from API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(
          "https://lunarsenterprises.com:4000/aims/list-company",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch companies");
        }
        const { data } = await response.json();
        setCompanyList(data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);
  // Empty dependency array means this runs once on mount

  const handleSelectFormat = (format) => {
    setSelectedFormats((prev) => {
      if (prev.includes(format)) {
        return prev.filter((f) => f !== format);
      }
      return [...prev, format];
    });
  };

  const handleShare = (platform) => {
    const shareContent = selectedFormats
      ?.map((format) => {
        const index = formats.indexOf(format);
        return editedContents[index] || format; // Use edited content if available
      })
      .join("\n\n");

    const emailSubject = "Shared Email Formats";
    const emailBody = encodeURIComponent(shareContent);
    const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareContent
    )}`;

    if (platform === "email") {
      window.open(emailLink, "_blank");
    } else if (platform === "whatsapp") {
      window.open(whatsappLink, "_blank");
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditContent = (index, newContent) => {
    const updatedContents = [...editedContents];
    updatedContents[index] = newContent;
    setEditedContents(updatedContents);
  };

  const handleSelectCompany = (companyId) => {
    setSelectedCompanies((prev) => {
      if (prev.includes(companyId)) {
        return prev.filter((id) => id !== companyId);
      }
      return [...prev, companyId];
    });
  };

  const handleConfirmSendEmails = async () => {
    try {
      // Fetch clients for selected companies
      const response = await fetch(
        "https://lunarsenterprises.com:4000/aims/clients", // API URL
        {
          method: "GET", // Use GET method
          headers: {
            "Content-Type": "application/json",
            company_id: selectedCompanies.join(","), // Send company IDs in the header
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        setClientList(result); // Store the fetched clients
        setIsClientModalOpen(true); // Open client modal
      } else {
        console.error("Failed to fetch clients");
        alert("Failed to fetch clients");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      alert("Error fetching clients");
    } finally {
      setIsModalOpen(false); // Close company modal
    }
  };

  const handleSelectClient = (clientId) => {
    setSelectedClients((prev) => {
      if (prev.includes(clientId)) {
        return prev.filter((id) => id !== clientId);
      }
      return [...prev, clientId];
    });
  };

  const handleConfirmSendToClients = async () => {
    // Get the emails of the selected clients
    const selectedEmails = clientList
      .filter((client) => selectedClients.includes(client.client_id)) // Find the clients whose IDs are selected
      .map((client) => client.client_email_id); // Extract their email IDs
  
    const emailSubject = "Welcome Mail";
    const emailContent = selectedFormats
      ?.map((format) => {
        const index = formats.indexOf(format);
        return editedContents[index];
      })
      .join("\n\n");
  
    const payload = {
      email: selectedEmails, // Use emails instead of client_ids
      subject: emailSubject,
      content: emailContent,
    };
  
    try {
      const response = await fetch(
        "https://lunarsenterprises.com:4000/aims/bulk/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        console.log("Emails sent successfully!", result);
        alert(response.data.message);
      } else {
        console.error("Failed to send emails");
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      alert("Error sending emails");
    } finally {
      setIsClientModalOpen(false);
      setSelectedCompanies([]);
      setSelectedClients([]);
    }
  };
  

  return (
    <>
      <div className="activity-head">
        <h3>Emails/Updates</h3>
      </div>
      <div className="mail-box">
        <h5>Email Format</h5>
        <div className="mail-formates">
          <div className="mail-formats">
            <div className="mail-column">
              {formats?.map((content, index) => (
                <Paragraph
                  key={index}
                  content={content}
                  title={titles[index]}
                  onSelect={() => handleSelectFormat(content)}
                  isSelected={selectedFormats.includes(content)}
                  onEdit={(newContent) => handleEditContent(index, newContent)}
                />
              ))}
            </div>
            <div className="button-container">
              <button
                onClick={() => handleShare("email")}
                className="share-button"
              >
                Share via Email
              </button>

              <button onClick={handleOpenModal} className="share-button">
                Send Bulk Email
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Company Selection Modal */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <h4>Select Companies for Bulk Email</h4>
          <div className="company-selection">
            {companyList?.map((company) => (
              <div key={company.c_id} className="company-checkbox">
                <label>
                  <input
                    type="checkbox"
                    value={company.c_id}
                    onChange={() => handleSelectCompany(company.c_id)}
                    checked={selectedCompanies.includes(company.c_id)}
                  />
                  {company.c_name} ({company.c_mail})
                </label>
              </div>
            ))}
          </div>
          <div className="modal-actions">
            <button onClick={handleCloseModal}>Cancel</button>
            <button onClick={handleConfirmSendEmails}>Confirm</button>
          </div>
        </Modal>
      )}

      {/* Client Selection Modal */}
      {isClientModalOpen && (
        <Modal
          isOpen={isClientModalOpen}
          onClose={() => setIsClientModalOpen(false)}
        >
          <h4>Select Clients for Email</h4>
          <div className="client-selection">
            {clientList?.map((client) => (
              <div key={client.client_id} className="client-checkbox">
                <label>
                  <input
                    type="checkbox"
                    value={client.client_id}
                    onChange={() => handleSelectClient(client.client_id)}
                    checked={selectedClients.includes(client.client_id)}
                  />
                  {client.client_name} ({client.client_email_id})
                </label>
              </div>
            ))}
          </div>
          <div className="modal-actions">
            <button onClick={() => setIsClientModalOpen(false)}>Cancel</button>
            <button onClick={handleConfirmSendToClients}>Send Emails</button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Email;

import React, { PureComponent } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

const data = [
  { name: 'Total Companies', value: 20 },
];

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label={({ cx, cy }) => (
              <text 
                x={cx} 
                y={cy} 
                dy={6}  
                style={{}}
                textAnchor="middle" 
                fill="#363666"
                fontSize={18}
              >
                Companies: 20
              </text>
            )}
            
            isAnimationActive={false}
          >
            {/* Use a single color cell for the pie chart */}
            <Cell fill="#363666" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Client from "../images/client.png"; // Replace with actual image path

function Company() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [sign, setSign] = useState(null);
  const [seal, setSeal] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [importFile, setImportFile] = useState(null); // State to store import file

  useEffect(() => {
    CompanyList();
  }, []);

  // Fetching company list from API
  const CompanyList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company",
        {}
      );
      setList(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddCompany = () => {
    navigate("/addcompany");
  };

  const handleEditCompany = (client) => {
    navigate("/editcompany", { state: { company: client } });
  };

  const handleDeleteCompany = (id) => {
    console.log(`Delete company with id: ${id}`);
    // Implement the delete functionality here
  };

  // Handle showing bank details in a modal
  const handleShowBankDetails = (bankDetails) => {
    setSelectedBankDetails(bankDetails);
    setShowModal(true);
  };

  const DeleteApi = async (salesId) => {
       // Ask for confirmation before proceeding with deletion
       const confirmDelete = window.confirm(
        "Are you sure you want to delete this user?"
      );
  
      if (!confirmDelete) {
        return; // Exit if the user cancels the deletion
      }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/delete-company",
        { c_id: salesId } // Pass sales_id in the request body
      );
      console.log(response, "response");

      if (response.status === 200) {
        alert(response.data.message);
        CompanyList(); // Refresh the sales list after deletion
      } else {
        alert("Failed to delete the sales record. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleShowImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  // Handle uploading sign and seal images
  const handleShowUploadModal = (companyId) => {
    setSelectedCompanyId(companyId);
    setShowUploadModal(true);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("sign", sign);
    formData.append("seal", seal);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/company/internal",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            company_id: selectedCompanyId, // Add company_id to the headers
          },
        }
      );
      console.log(selectedCompanyId, "selectedCompanyId");
      console.log(formData, "formData");

      if (response.data.result) {
        alert(response.data.message);
        setShowUploadModal(false); // Close the modal after successful upload
        CompanyList();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("An error occurred while uploading. Please try again.");
    }
  };

  // Handle search input changes
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle importing the Excel file
  const handleImport = async () => {
    const formData = new FormData();
    formData.append("sheet", importFile); // Add the uploaded file to the formData

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/company/sheet/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result) {
        alert(response.data.message);
        setList(response.data.data); // Update the company list with the imported data
      } else {
        alert("Failed to import company data.");
      }
    } catch (error) {
      console.error("Error importing company data:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // Filter the company list based on the search term
  // Filter the company list based on the search term
  const filteredList = list.filter((company) =>
    company.c_name
      ? company.c_name.toLowerCase().includes(searchTerm.toLowerCase())
      : false
  );

  //.............Company Export..............//

  const handleExport = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/company/sheet/export",
        {}
      );

      if (response.data.result) {
        const fileUrl = response.data.filepath;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "company.xlsx"); // Set the default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert("Failed to export company data.");
      }
    } catch (error) {
      console.error("Error exporting company data:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <button className="export-button" onClick={handleExport}>
            <i className="fas fa-upload"></i> Export
          </button>
          <button
            className="import-button"
            // onClick={() => document.getElementById("importFileInput").click()}
          >
            <i className="fas fa-download"></i> Import
          </button>
          <input
            type="file"
            id="importFileInput"
            style={{ display: "none" }}
            // onChange={(e) => setImportFile(e.target.files[0])}
          />
        
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <button onClick={handleAddCompany} style={{ marginRight: "50px" }}>
          ADD COMPANY
          <i className="fas fa-plus"></i>
        </button>
      </div>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>COMPANY LOGO</th>
            <th>COMPANY ID</th>
            <th>COMPANY NAME</th>
            <th>COMPANY ADDRESS</th>
            <th>COMPANY EMAIL</th>
            <th>PHONE NUMBER</th>
            <th>TYPE OF COMPANY</th>
            <th>RESPONSIBLE PERSON</th>
            <th>SIGN</th>
            <th>SEAL</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {filteredList?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <img
                  src={
                    `https://lunarsenterprises.com:4000/${client.c_logo}` ||
                    Client
                  }
                  alt="Company Logo"
                  className="company-logo"
                />
              </td>
              <td>{client.c_id}</td>
              <td>{client.c_name}</td>
              <td>{client.c_address}</td>
              <td>{client.c_mail}</td>
              <td>{client.c_mobile}</td>

              <td>{client.c_category}</td>
              <td>{client.c_person}</td>
              <td>
                {client.c_sign ? (
                  <i
                    className="fas fa-eye"
                    onClick={() =>
                      handleShowImage(
                        `https://lunarsenterprises.com:4000/${client.c_sign}`
                      )
                    }
                    style={{ cursor: "pointer", color: "#000" }}
                  ></i>
                ) : (
                  "No Sign"
                )}
              </td>
              <td>
                {client.c_seal ? (
                  <i
                    className="fas fa-eye"
                    onClick={() =>
                      handleShowImage(
                        `https://lunarsenterprises.com:4000/${client.c_seal}`
                      )
                    }
                    style={{ cursor: "pointer", color: "#000" }}
                  ></i>
                ) : (
                  "No Seal"
                )}
              </td>
              <td>
                <div className="action-icons">
                  <div onClick={() => handleEditCompany(client)}>
                    <i className="fas fa-edit"></i>
                  </div>
                  <div onClick={() => handleShowBankDetails(client)}>
                    <i className="fas fa-eye"></i>
                  </div>
                  <div onClick={() => DeleteApi(client.c_id)}>
                    <i className="fas fa-trash"></i>
                  </div>
                  <div
                    onClick={() => handleShowUploadModal(client.c_id)}
                    title="Upload Sign & Seal"
                  >
                    <i className="fas fa-upload"></i>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for showing bank details */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display bank details */}
          <p>Bank Name: {selectedBankDetails.c_bank_name}</p>
          <p>Account Number: {selectedBankDetails.c_account_no}</p>
          <p>IFSC Code: {selectedBankDetails.c_ifsc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for showing sign or seal image */}
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Sign or Seal"
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for uploading sign and seal */}
      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Sign & Seal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Sign</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setSign(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Seal</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setSeal(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFileUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Company;

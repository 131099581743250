import React, { useEffect, useState } from "react";
import "./UserList.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const UserList = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [show, setShow] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [lastEditDetails, setLastEditDetails] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterAmount, setFilterAmount] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [companyList, setCompanyList] = useState([]); // Store company list
  const [selectedCompanyId, setSelectedCompanyId] = useState(""); // Store selected company ID
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [revertedClients, setRevertedClients] = useState([]); // State for reverted clients

  useEffect(() => {
    fetchCompanyList(); // Fetch companies when component mounts
  }, []);

  // Fetch sales list whenever the selectedCompanyId changes
  useEffect(() => {
    if (selectedCompanyId) {
      UserList();
    }
  }, [selectedCompanyId]);
  useEffect(() => {
    UserList();
  }, []);

  // Fetch company list from API
  const fetchCompanyList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/list-company"
      );
      setCompanyList(response.data.data); // Set company data
    } catch (error) {
      console.error("Error fetching company list:", error);
    }
  };

  // Fetch sales list based on selected company ID
  const UserList = async () => {
    try {
      const response = await axios.get(
        "https://lunarsenterprises.com:4000/aims/users",
        {},
        {
          headers: {
            company_id: selectedCompanyId, // Pass company_id as a header
          },
        }
      );
      setList(response.data);
      setFilteredClients(response.data); // Initialize filtered clients
    } catch (error) {
      console.error("Error fetching sales list:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddUser = () => {
    navigate("/addsales");
  };

  const handleEditUser = (salesId) => {
    navigate(`/editsales/${salesId}`);
  };

  const DeleteApi = async (userId) => {
    // Ask for confirmation before proceeding with deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the deletion
    }

    try {
      const response = await axios.post(
        `https://lunarsenterprises.com:4000/aims/users/delete/${userId}`
      );

      if (response.status === 200) {
        alert(response.data.message);
        UserList(); // Refresh the user list after deletion
      } else {
        alert("Failed to delete the user. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleLastEditClick = (details) => {
    setLastEditDetails(details);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleFilterClose = () => setFilterShow(false);

  const handleFilter = () => {
    let filtered = list;
    if (filterName) {
      filtered = filtered.filter((client) =>
        client.client_name.toLowerCase().includes(filterName.toLowerCase())
      );
    }
    if (filterAmount) {
      filtered = filtered.filter((client) =>
        client.sales_total_amount.toString().includes(filterAmount)
      );
    }
    if (filterDate) {
      filtered = filtered.filter((client) =>
        client.sales_date.includes(filterDate)
      );
    }
    setFilteredClients(filtered);
    handleFilterClose();
  };

  const handleClearFilter = () => {
    setFilterName("");
    setFilterAmount("");
    setFilterDate("");
    setFilteredClients(list);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-CA", options);
  };

  const handleSearchChange = (e) => {
    setFilterName(e.target.value);
    const filtered = list.filter((client) =>
      client.user_name && client.user_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredClients(filtered);
  };
  

  // Handle company selection change
  const handleCompanyChange = (e) => {
    setSelectedCompanyId(e.target.value); // Update selected company ID
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //.............Export Sales Api..............//

  const handleExport = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/sales/sheet/export",
        {},
        {
          headers: {
            company_id: selectedCompanyId, // Pass selected company_id as a header
          },
        }
      );

      if (response.data.result && response.data.filepath) {
        const fileUrl = response.data.filepath;

        // Trigger file download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "sales.xlsx"); // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
      } else {
        alert("Failed to export the sales data. Please try again.");
      }
    } catch (error) {
      console.error("Error exporting sales data:", error);
      alert("An error occurred during export. Please try again.");
    }
  };

  return (
    <>
      <div className="sub-head">
        {/* <div className="head-icons">
          <button className="export-button" onClick={handleExport}>
            <i className="fas fa-upload"></i> Export
          </button>
          <button className="import-button" onClick={{}}>
            <i className="fas fa-download"></i> Import
          </button>
        </div> */}

        <div className="company-filter">
          <label className="text1">Select Company:</label>
          <select
            value={selectedCompanyId}
            onChange={handleCompanyChange}
            className="company-dropdown"
          >
            <option value="">Select a company</option>
            {companyList?.map((company) => (
              <option key={company.c_id} value={company.c_id}>
                {company.c_name}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="head-icons">
          <button onClick={handleAddUser}>
            ADD SALES
            <i className="fas fa-plus"></i>
          </button>
        </div> */}
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={filterName}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>

            <th>COMPANY NAME</th>
            <th>USER NAME</th>
            <th>USER EMAIL</th>
            <th>USER MOBILE</th>
            <th>ROLE</th>
            <th>USER STATUS</th>
            <th>CREATED AT</th>
            <th>UPDATED AT</th>
            <th>PASSWORD</th>
            <th>JOINING DATE</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>

              <td>{client.c_name}</td>
              <td>{client.user_name}</td>
              <td>{client.user_email}</td>
              <td>{client.user_mobile}</td>
              <td>{client.user_role}</td>
              <td>{client.user_status}</td>

              <td>{client.user_created_at}</td>
              <td>{client.user_updated_at}</td>
              <td>{client.password}</td>
              <td>{moment(client.user_joining_date).format("YYYY-MM-DD")}</td>
              <td>
                <div style={{ display: "flex", gap: "10px" }}>
                  {/* <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client.sales_id)}
                  ></i> */}
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.user_id)}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for reverting previous sales */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Revert Previous Sales List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {revertedClients.length === 0 ? (
            <p>No previous sales data available.</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Company Name</th>
                  <th>Product</th>
                  <th>Unit</th>
                  <th>Discount</th>
                  <th>Total Amount</th>
                  <th>Sales Date</th>
                </tr>
              </thead>
              <tbody>
                {revertedClients?.map((client, index) => (
                  <tr key={index}>
                    <td>{client.client_name}</td>
                    <td>{client.c_name}</td>
                    <td>{client.sales_product}</td>
                    <td>{client.sales_unit}</td>
                    <td>{client.sales_discount}</td>
                    <td>{client.sales_total_amount}</td>

                    <td>
                      {moment(client.client.sales_date).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={{}}>
            Revert Previous List
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserList;

import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import Table from "react-bootstrap/Table";
import Productpie from "../Productpie/productpie";
import axios from "axios";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    client_total_count: 0,
    client_total_due_amount: 0,
    next_due_client: 0,
    next_due_amount: 0,
    next_due_date: "",
    next_due_date_client: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4000/aims/dashboard",
          {}
        );
        if (response.data.result) {
          setDashboardData(response.data);
        } else {
          setError("Failed to retrieve data");
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchDashboardData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="table-container">
        <Table striped bordered hover variant="light">
          <thead>
            <tr className="double-height">
              <th>Total number of clients</th>
              <th>Total due amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{dashboardData.client_total_count}</td>
              <td>{dashboardData.client_total_due_amount}</td>
            </tr>
          </tbody>
        </Table>

        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th colSpan={3}>Next due</th>
            </tr>
            <tr>
              <th>No of clients</th>
              <th>Due amount</th>
              <th>Date of due amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{dashboardData.next_due_client}</td>
              <td>{dashboardData.next_due_amount}</td>
              <td>{dashboardData.next_due_date}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="pie-container">
        <div className="sub-pie">
          <div className="text-center">Company</div>
          <Productpie />
        </div>
      </div>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Client Name as per Bank</th>
            <th>Rental amount due</th>
            <th>Account Number</th>
            <th>IBAN/IFSC/Routing No</th>
            <th>Bank Name</th>
            <th>Branch</th>
            <th>Wire numbers</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {dashboardData?.next_due_date_client?.map((client, index) => (
            <tr key={index}>
              <td>{client.client_name}</td>
              <td>{client.bank_as_per_account_name.trim()}</td>
              <td>{client.payout_amount}</td>
              <td>{client.bank_account_no}</td>
              <td>{client.bank_ifsc_no}</td>
              <td>{client.bank_name}</td>
              <td>{client.bank_branch_name}</td>
              <td>{client.wire_numbers}</td>
              <td>{client.country}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Dashboard;
